import React from "react"

import Loadable from "react-loadable-visibility/react-loadable"
import Loading from "../components/Otros/Loading/Loading"

import "../styles/App.scss"

const LoadableComponent = React.memo(
  Loadable.Map({
    loader: {
      Layout: () => import("../components/Otros/Layout/Layout"),
      RedirectScroll: () =>
        import("../components/Otros/RedirectScroll/RedirectScroll"),
      Cuarenta: () => import("../components/Cuarenta"),
      Slider: () => import("../components/Home/Slider/Slider"),
      Grupo: () => import("../components/Home/Grupo/Grupo"),
      Lider: () => import("../components/Home/Lider/Lider"),
      Clientes: () => import("../components/Home/Clientes/Clientes"),
      Historia: () => import("../components/Home/Historia/Historia"),
      Visionarios: () => import("../components/Home/Visionarios/Visionarios"),
      Sedes: () => import("../components/Home/Sedes/Sedes"),
      VisionMision: () =>
        import("../components/Home/VisionMision/VisionMision"),
      Principios: () => import("../components/Home/Principios/Principios"),
      Contamos: () => import("../components/Home/Contamos/Contamos"),
      Soluciones: () => import("../components/Home/Soluciones/Soluciones"),
      Areas: () => import("../components/Home/Areas/Areas"),
      ServicioTecnico: () =>
        import("../components/Home/ServicioTecnico/ServicioTecnico"),
      ControlMonitoreo: () =>
        import("../components/Home/ServicioTecnico/ControlMonitoreo"),
      Eventos: () => import("../components/Home/Eventos/Eventos"),
      UnderEventos: () => import("../components/Home/Eventos/UnderEventos"),
      Noticias: () => import("../components/Home/Noticias/Noticias"),
      Trabaja: () => import("../components/Home/Trabaja/Trabaja"),
      Contacto: () => import("../components/Home/Contacto/Contacto"),
    },
    loading: Loading,
    render(loaded, props) {
      let Layout = loaded.Layout.default
      let RedirectScroll = loaded.RedirectScroll.default
      let Cuarenta = loaded.Cuarenta.default
      let Slider = loaded.Slider.default
      let Grupo = loaded.Grupo.default
      let Lider = loaded.Lider.default
      let Clientes = loaded.Clientes.default
      let Historia = loaded.Historia.default
      let Visionarios = loaded.Visionarios.default
      let Sedes = loaded.Sedes.default
      let VisionMision = loaded.VisionMision.default
      let Principios = loaded.Principios.default
      let Contamos = loaded.Contamos.default
      let Soluciones = loaded.Soluciones.default
      let Areas = loaded.Areas.default
      let ServicioTecnico = loaded.ServicioTecnico.default
      let ControlMonitoreo = loaded.ControlMonitoreo.default
      let Eventos = loaded.Eventos.default
      let UnderEventos = loaded.UnderEventos.default
      let Noticias = loaded.Noticias.default
      let Trabaja = loaded.Trabaja.default
      let Contacto = loaded.Contacto.default

      return (
        <Layout titulo="Home">
          <RedirectScroll />
          <Cuarenta />
          <Slider />
          <Grupo />
          <Lider />
          <Clientes />
          <Historia />
          <Visionarios />
          <Sedes />
          <VisionMision />
          <Principios />
          <Contamos />
          <Soluciones />
          <Areas />
          <ServicioTecnico />
          <ControlMonitoreo />
          <Eventos />
          <UnderEventos />
          <Noticias />
          <Trabaja />
          <Contacto />
        </Layout>
      )
    },
  })
)

const Home = () => {
  return <LoadableComponent />
}

export default React.memo(Home)
